const english = {
    dir: "ltr",
    language: "en",

    "all": {
        "all_ok_btn_title": "Ok",
        "all_cancel_btn_title": "Cancel",
        "all_done_btn_title": "Done",
        "all_back_btn_title": "Back",
        "all_yes_btn_title": "Yes",
        "all_no_btn_title": "No",
        "all_ar": "العربية",
        "all_en": "English",
        "all_or_lbl_title": "OR",
        "all_chage_language": "Change language",
        "all_alert": "Alert",
        "all_connected": "Connected",
        "all_ok": "OK",
        "all_cancel": "Cancel",
        "all_done": "Done",
        "all_egp_currency_title": "EGP",
        "all_usd_currency_title": "USD",
        "all_eur_currency_title": "EUR",
        "all_sar_currency_title": "SAR",
        "all_aed_currency_title": "AED",
        "all_no_internet_title": "No Internet",
        "all_no_results": "No Results Found"
    },
    "Login": {
        "login_header_title": "Hello, please enter the following data",
        "login_input_email": "Email Address",
        "login_input_password": "Password",
        "login_button_login": "Login",
        "login_remember_me": "Remember me later",
        "login_forgot_password": "Forgot Password ?",
        "login_email_required": "Please enter your email address",
        "login_email_valid": "Wrong Email Address",
        "login_password_required": "Password cannot be empty",
        "login_password_min_valid": "Password shouldn't be less than 6 characters",
        "login_password_max_valid": "Password must be between 6 and 20 characters",
        "login_show_back_error": "Wrong username or password"
    },
    "ForgotPassword": {
        "forgotPassword_header_title": "Hello, please enter the following data to recover the password",
        "forgotPassword_input_email": "Email Address",
        "forgotPassword_button_forgotPassword": "Restore password",
        "forgotPassword_email_required": "Please enter your email address",
        "forgotPassword_email_valid": "Wrong Email or Phone Number",
        "forgotPassword_show_back_error": "Error While Sending Email",
        "forgotPassword_back_login": "Back To Login"
    },
    "VerificationCode": {
        "verificationCode_header_title": "Hello, please enter the following data to recover the password",
        "verificationCode_input_code": "Enter the sent code",
        "verificationCode_button_verificationCode": "Confirm Code",
        "verificationCode_code_required": "Please enter your code",
        "verificationCode_code_invalid": "Invalid verification code",
        "verificationCode_show_back_error": "Invalid verification code"
    },
    "ResetPassword": {
        "resetPassword_header_title": "Hello, please enter the following data",
        "resetPassword_input_newPassword": "New Password",
        "resetPassword_input_confirmPassword": "Confirm New Password",
        "resetPassword_button_resetPassword": "Change Password",
        "resetPassword_show_back_error": "We cannot serve your request now, please try again later.",
        "new_password_field_error_msg_invalid_password": "New Password shouldn't be less than 6 characters",
        "new_password_field_error_msg_empty": "New password cannot be empty",
        "password_field_strength_default_description": "Password must be between 6 and 20 characters ",
        "password_field_error_msg_password_doesnt_match": "Password must match the new password"
    },
    "Toolbar": {
        "toolbar_language": "العربية",
        "toolbar_change_password": "Change Password",
        "toolbar_logout": "Logout"
    },
    "Header": {
        "header_dashboard": "Dashboard",
        "header_all_facilities": "All Facilities",
        "header_map_exploler": "Map Exploler",
        "header_list_cities": "List Cities",
        "header_manage_users": "Manage Users"
    },
    "Hotels": {
        "hotels_title": "Hotels",
        "hotels_search": "Search",
        "hotels_status": "Status",
        "hotels_city": "City",
        "hotels_areas": "Areas",
        "hotels_area": "Area",
        "hotels_star": "Star",
        "hotels_reset_filter": "Reset Filter",
        "hotels_new_facility": "Add New Facility",
        "hotels_photo": "Photo",
        "hotels_id": "ID",
        "hotels_facility_name": "Facility Name",
        "hotels_distinace_haram": "Distinace Haram",
        "hotels_review": "Review",
        "hotels_actions": "Actions",
        "hotels_view": "View",
        "hotels_edit": "Edit",
        "hotels_direction": "Direction",
        "hotels_delete": "Delete",
        "hotels_active": "Active",
        "hotels_deactivate": "Deactivate",
        "hotels_details": "Details",
        "hotels_facility_type": "Facility Type",
        "hotels_choose_facility_type": "choose a facility Type",
        "hotels_country": "Country",
        "hotels_choose_country": "choose a country",
        "hotels_choose_city": "choose a city",
        "hotels_choose_area": "choose a area",
        "hotels_building_number": "Building Number",
        "hotels_street": "Street",
        "hotels_district": "District",
        "hotels_facilityPobox": "Facility Pobox",
        "hotels_facilityPhone": "Facility Phone",
        "hotels_facilityEmail": "Facility Email",
        "hotels_facilityClassification_type": "Facility Classification Type",
        "hotels_choose_facilityClassification_type": "choose a facility classification type",
        "hotels_facilityClassification_startDate": "Facility Classification Start Date",
        "hotels_facilityClassification_endDate": "Facility Classification End Date",
        "hotels_english": "English",
        "hotels_arabic": "Arabic",
        "hotels_placeName": "Place Name",
        "hotels_location": "Location",
        "hotels_description": "Description",
        "hotels_mapLocation": "Map Location",
        "hotels_hotelCoordinates": "Hotel Coordinates",
        "hotels_coordinates_lat": "X COORDINATE (LAT)",
        "hotels_coordinates_long": "Y COORDINATE (LONG)",
        "hotels_haramCoordinates": "Haram Doors Coordinates",
        "hotels_rooms": "Rooms",
        "hotels_pricingAmount": "Pricing Amount",
        "hotels_renewalCount": "Renewal Count",
        "hotels_floorsCount": "Floors Count",
        "hotels_deluxeRoomsCount": "Deluxe Rooms Count",
        "hotels_regularRoomsCount": "Regular Rooms Count",
        "hotels_regularSuitesCount": "Regular Suites Count",
        "hotels_deluxeSuitesCount": "Deluxe Suites Count",
        "hotels_parkingCount": "Parking Count",
        "hotels_facilities": "Facilities",
        "hotels_information": "Information",
        "hotels_facilityOwner": "Facility Owner",
        "hotels_operatorName": "Operator Name",
        "hotels_operatorGender": "Operator Gender",
        "hotels_choose_operatorGender": "choose a operator gender",
        "hotels_licensingInvestor": "Licensing Number For Investor",
        "hotels_facilityLicensingStartDate": "Facility Licensing Start Date",
        "hotels_photos": "Photos",
        "hotels_uploadPhotos": "Upload Photos",
        "hotels_photos_allRemove": "Remove all Photos",
        "hotels_photos_update": "Update",
        "hotels_photos_remove": "Remove",
        "hotels_add": "Add",
        "hotels_back": "Back",
        "hotels_edit_facility": "Edit Facility",
        "hotels_distance_haramDoor": "Distance From Haram Door",
        "hotels_typeID": "Type ID",
        "hotels_jumpTo": "Jump To",
        "hotels_previous": "Previous",
        "hotels_verify": "Verify",
        "hotels_unVerify": "Un Verify",
        "hotels_next": "Next",
        "hotels_coordinates_haram_lat": "X COORDINATE (LAT) Haram Door",
        "hotels_coordinates_haram_long": "Y COORDINATE (LONG) Haram Door",
        "hotels_filterBy": "Filter by",
        "hotels_resetFilter": "Reset Filter",
        "hotels_hotel": "Hotel",
        "hotels_building": "Building",
        "hotels_furnished_housing": "Furnished housing units",
        "hotels_2stars": "2 stars",
        "hotels_3stars": "3 stars",
        "hotels_4stars": "4 stars",
        "hotels_5stars": "5 stars",
        "hotels_all": "All",
        "hotels_from0to500": "from 0 - to 500",
        "hotels_from500to1000": "from 500 - to 1000",
        "hotels_from1000to2000": "from 1000 - to 2000",
        "hotels_from2000to3000": "from 2000 - to 3000",
        "hotels_from3000to4000": "from 3000 - to 4000",
        "hotels_from4000to5000": "from 4000 - to 5000",
        "hotels_from5000": "from 5000",
        "hotels_distanceFromHaram": "Distance from Haram",
        "hotels_starRating": "Star Rating"
    },
    "Cities": {
        "cities_title": "List Cities",
        "cities_areasTitle": "List Areas",
        "cities_id": "ID",
        "cities_cityName": "City Name",
        "cities_totalHotels": "Total Hotels",
        "cities_reviewedHotels": "Reviewed Hotels",
        "cities_options": "Options",
        "cities_open": "Open",
        "cities_areaName": "Area Name",
        "cities_viewAll": "View All",
        "cities_startReview": "Start Review",
    },
    "UsersManagement": {
        "usersManagement_users": "Manage Users",
        "usersManagement_groups": "Manage Groups",
        "usersManagement_usersTitle": "Users",
        "usersManagement_roles": "Roles",
        "usersManagement_search": "Search",
        "usersManagement_status": "Status",
        "usersManagement_active": "Active",
        "usersManagement_pending": "Pending",
        "usersManagement_deactivate": "Deactivate",
        "usersManagement_reset": "Reset Filter",
        "usersManagement_new_user": "Add New User",
        "usersManagement_name": "Name",
        "usersManagement_emailAddress": "Email Address",
        "usersManagement_mobileNumber": "Mobile Number",
        "usersManagement_admin": "Admin",
        "usersManagement_actions": "Actions",
        "usersManagement_edit": "Edit",
        "usersManagement_changePassword": "Change Password",
        "usersManagement_delete": "Delete",
        "usersManagement_fullName": "Full Name",
        "usersManagement_phoneNumber": "Phone Number",
        "usersManagement_isAdmin": "IS SUPER ADMIN",
        "usersManagement_yes": "Yes",
        "usersManagement_no": "No",
        "usersManagement_role": "Role",
        "usersManagement_choose_role": "choose a role",
        "usersManagement_new_user_button": "New User",
        "usersManagement_edit_user_button": "Edit User",
        "usersManagement_newPassword": "New Password",
        "usersManagement_confirmNewPassword": "Confirm New Password",
        "usersManagement_new_role": "Add New Role",
        "usersManagement_role_name": "Role Name",
        "usersManagement_save": "Save",
        "usersManagement_edit_role": "Edit Role",
        "usersManagement_create_new_role": "Create New Role",
    }
};
export default english;