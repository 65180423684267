import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import moment from "moment";
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import { fetchFeatures } from "../../../store/actions/lookups";
import { changeVerifyHotel, resetHotels } from "../../../store/actions/hotels";

import Spinner from '../../UI/Spinner/Spinner';
import Snackbars from '../../UI/Snackbars/Snackbars';

import GoogleMap from '../GoogleMap/GoogleMap';

import './ViewHotel.css';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ViewHotel = (props) => {
    const { loading, language, lang, styleDir, HotelID, AreaID, From, type, HotelSelected, Features, statusAPI, message, onFetchFeatures, onChangeVerifyHotel, onResetHotels } = props;

    const history = useHistory();

    const [jumpToState, setJumpToState] = useState();

    const [value, setValue] = useState(0);

    const jumpToHandler = (prop) => (event) => {
        setJumpToState(event.target.value)
    };

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        async function fetchData() {
            await onFetchFeatures();
        }
        fetchData();
    }, [lang]);

    useEffect(() => {
        if(statusAPI === 201){
            setInterval(() => {
                onResetHotels();
              }, 6000);
        }
    }, [statusAPI]);

    const ChangeVerifyHotelHandleClick = (HotelID, status) => {
        onChangeVerifyHotel(HotelID, status);
    }

    const jumpToHandlerClick = () => {
        if(jumpToState !== '' && jumpToState > 0){
            history.push(`/admin/hotels/view/` + jumpToState);
        }
    }

    const NextHandlerClick = () => {
        //let nextID = Number(HotelID)+1;
        if(From === 'area'){
            if(type === 'unverify'){
                history.push(`/admin/area/` + AreaID + `/hotel-unverify/` + HotelSelected?.next);
            }else{
                history.push(`/admin/area/` + AreaID + `/hotel/` + HotelSelected?.next);
            }
        }else{
            history.push(`/admin/hotels/view/` + HotelSelected?.next);
        }
    }

    const PreviousHandlerClick = () => {
        //let nextID = Number(HotelID)-1;
        if(From === 'area'){
            if(type === 'unverify'){
                history.push(`/admin/area/` + AreaID + `/hotel-unverify/` + HotelSelected?.prev);
            }else{
                history.push(`/admin/area/` + AreaID + `/hotel/` + HotelSelected?.prev);
            }
        }else{
            history.push(`/admin/hotels/view/` + HotelSelected?.prev);
        }
    }

    return (
        <div className="ViewHotel-Container">
            {loading
                ? <Spinner />
                : <div className={`ViewHotel-ViewContent`}>
                    <div className={`ViewHotel-HeadContent`}>
                        <div className={`ViewHotel-HotelNavContainer`}>
                            <div className="ViewHotel-HotelNav-Item">{HotelSelected?.en_facility_name}</div>
                            <div className="ViewHotel-HotelNav-Item">{HotelSelected?.city}</div>
                            <div className="ViewHotel-HotelNav-Item">{HotelSelected?.area}</div>
                            <div className="ViewHotel-HotelNav-Item">{HotelSelected?.stars}</div>
                        </div>

                        <div className={`ViewHotel-HotelJumpContainer`}>
                            <div onClick={jumpToHandlerClick} className="ViewHotel-HotelJumpButton">{language.Hotels.hotels_jumpTo}</div>
                            <TextField
                                className={`ViewHotel-Input`}
                                type="number"
                                id="jumpTo"
                                name="jumpTo"
                                label={<div>
                                    {language.Hotels.hotels_typeID}
                                </div>}
                                variant="outlined"
                                value={jumpToState}
                                onChange={jumpToHandler()}
                                placeholder={language.Hotels.hotels_typeID} />
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-HotelJumpIDTitle" >{'ID : ' + HotelSelected?.id}</Typography>
                        </div>

                        <div className={`ViewHotel-HotelActionContainer`}>
                            <div onClick={PreviousHandlerClick} className={HotelSelected?.prev !== null ? "ViewHotel-PreviousHotelButton" : "ViewHotel-PreviousHotelButton-disabled"} >
                                    <Typography component={'span'} variant={'body2'} >{language.Hotels.hotels_previous}</Typography>
                            </div>

                            {HotelSelected?.verified === 0
                                ? (<div onClick={(ID, Status) => ChangeVerifyHotelHandleClick(HotelSelected?.id, 1)} className="ViewHotel-VerifyHotelButton" >
                                    <Typography component={'span'} variant={'body2'} >{language.Hotels.hotels_verify}</Typography>
                                </div>)
                                : (<div onClick={(ID, Status) => ChangeVerifyHotelHandleClick(HotelSelected?.id, 0)} className="ViewHotel-UnVerifyHotelButton" >
                                    <Typography component={'span'} variant={'body2'} >{language.Hotels.hotels_unVerify}</Typography>
                                </div>)
                            }

                            <div onClick={NextHandlerClick} className={HotelSelected?.next !== null ? "ViewHotel-NextHotelButton" : "ViewHotel-NextHotelButton-disabled"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_next}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className="ViewHotel-DetailsContainer">
                        <div className="ViewHotel-DetailsHead">
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsHeadTitle" >{language.Hotels.hotels_details}</Typography>
                            <div className="ViewHotel-DetailsHeadButton" >
                                <NavLink to={'/admin/hotels/edit/' + HotelSelected?.id} className={"ViewHotel-Link"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_edit}</Typography>
                                </NavLink>
                            </div>
                        </div>
                        <div className="ViewHotel-DetailsContent">
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facility_type}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.type}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_area}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.area}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_district}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.district}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityEmail}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.email}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityClassification_startDate}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{moment(HotelSelected?.classification_start_date).format("YYYY-MM-DD")}</Typography>
                                </div>
                            </div>
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_country}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.country}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_building_number}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.buildings_count}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityPobox}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.pobox}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityClassification_type}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.stars}</Typography>
                                </div>
                            </div>
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_city}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.city}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_street}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.street}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityPhone}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.phone}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityClassification_endDate}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{moment(HotelSelected?.classification_end_date).format("YYYY-MM-DD")}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="ViewHotel-BoxTabs-Container">
                            <Box className="ViewHotel-BoxTabs" sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs className="ViewHotel-BoxTabs-Tabs" value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                                        <Tab className="ViewHotel-BoxTabs-Tab" label={language.Hotels.hotels_english} {...a11yProps(0)} />
                                        <Tab className="ViewHotel-BoxTabs-Tab" label={language.Hotels.hotels_arabic} {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel className="ViewHotel-TabPanel-Container" value={value} index={0}>
                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facility_name}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.en_facility_name}</Typography>
                                    </div>

                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_placeName}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.en_place_name}</Typography>
                                    </div>

                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_location}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.en_location}</Typography>
                                    </div>

                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_description}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.en_description}</Typography>
                                    </div>
                                </TabPanel>
                                <TabPanel className="ViewHotel-TabPanel-Container" value={value} index={1}>
                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facility_name}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.ar_facility_name}</Typography>
                                    </div>

                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_placeName}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.ar_place_name}</Typography>
                                    </div>

                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_location}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.ar_location}</Typography>
                                    </div>

                                    <div className="ViewHotel-DetailsRow">
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_description}{': '}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.ar_description}</Typography>
                                    </div>
                                </TabPanel>
                            </Box>
                        </div>
                    </div>

                    <div className="ViewHotel-MapLocationContainer">
                        <div className="ViewHotel-MapLocationHead">
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-MapLocationHeadTitle" >{language.Hotels.hotels_mapLocation}</Typography>
                            <div className="ViewHotel-MapLocationHeadButton" >
                                <NavLink to={'/admin/hotels/edit/' + HotelSelected?.id} className={"ViewHotel-Link"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_edit}</Typography>
                                </NavLink>
                            </div>
                        </div>
                        <div className="ViewHotel-MapLocationContent">
                            <div className="ViewHotel-MapLocation-Map">
                                {HotelSelected ? <GoogleMap CenterLat={HotelSelected?.lat} CenterLong={HotelSelected?.long} Hotels={[HotelSelected]} HotelSelected={null} /> : null}
                            </div>
                            <div className="ViewHotel-MapLocation-Coordinate">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_coordinates_lat}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.lat}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_coordinates_long}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.long}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_coordinates_haram_lat}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.harm_lat}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_coordinates_haram_long}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.harm_long}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_distance_haramDoor}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.harm_distance}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ViewHotel-DetailsContainer">
                        <div className="ViewHotel-DetailsHead">
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsHeadTitle" >{language.Hotels.hotels_rooms}</Typography>
                            <div className="ViewHotel-DetailsHeadButton" >
                                <NavLink to={'/admin/hotels/edit/' + HotelSelected?.id} className={"ViewHotel-Link"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_edit}</Typography>
                                </NavLink>
                            </div>
                        </div>
                        <div className="ViewHotel-DetailsContent">
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_pricingAmount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.pricing_amount}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_deluxeRoomsCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.deluxe_rooms_count}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_deluxeSuitesCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.deluxe_suites_count}</Typography>
                                </div>

                            </div>
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_renewalCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.renewal_count}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_regularRoomsCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.regular_rooms_count}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_parkingCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.parking_count}</Typography>
                                </div>
                            </div>
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_floorsCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.floors_count}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_regularSuitesCount}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.regular_suites_count}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ViewHotel-DetailsContainer">
                        <div className="ViewHotel-DetailsHead">
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsHeadTitle" >{language.Hotels.hotels_facilities}</Typography>
                            <div className="ViewHotel-DetailsHeadButton" >
                                <NavLink to={'/admin/hotels/edit/' + HotelSelected?.id} className={"ViewHotel-Link"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_edit}</Typography>
                                </NavLink>
                            </div>
                        </div>
                        <div className="ViewHotel-DetailsContent">
                            <div className="ViewHotel-FacilitiesGrid">
                                {Features.map(item => {
                                    return (<div key={item.id} className="ViewHotel-DetailsRow">
                                        {HotelSelected?.features.some((feature => (item.id === feature.id)))
                                            ? <DoneIcon className="ViewHotel-DoneIcon" />
                                            : <CloseIcon className="ViewHotel-CloseIcon" />
                                        }
                                        <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{item.name}</Typography>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="ViewHotel-DetailsContainer">
                        <div className="ViewHotel-DetailsHead">
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsHeadTitle" >{language.Hotels.hotels_information}</Typography>
                            <div className="ViewHotel-DetailsHeadButton" >
                                <NavLink to={'/admin/hotels/edit/' + HotelSelected?.id} className={"ViewHotel-Link"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_edit}</Typography>
                                </NavLink>
                            </div>
                        </div>
                        <div className="ViewHotel-DetailsContent">
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityOwner}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.owner}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_licensingInvestor}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.licensing_number_for_investor}</Typography>
                                </div>

                            </div>
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_operatorName}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.operator}</Typography>
                                </div>

                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_facilityLicensingStartDate}{': '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{moment(HotelSelected?.facility_licensing_start_date).format("YYYY-MM-DD")}</Typography>
                                </div>

                            </div>
                            <div className="ViewHotel-DetailsColumn">
                                <div className="ViewHotel-DetailsRow">
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-Label">{language.Hotels.hotels_operatorGender}{'Operator Gender: '}</Typography>
                                    <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsRow-title">{HotelSelected?.operator_gender}</Typography>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="ViewHotel-DetailsContainer">
                        <div className="ViewHotel-DetailsHead">
                            <Typography component={'span'} variant={'body2'} className="ViewHotel-DetailsHeadTitle" >{language.Hotels.hotels_photos}</Typography>
                            <div className="ViewHotel-DetailsHeadButton" >
                                <NavLink to={'/admin/hotels/edit/' + HotelSelected?.id} className={"ViewHotel-Link"} >
                                    <Typography component={'span'} variant={'body2'}>{language.Hotels.hotels_edit}</Typography>
                                </NavLink>
                            </div>
                        </div>
                        <div className="ViewHotel-DetailsContent">
                            <div className="ViewHotel-PhotosGrid">
                                {HotelSelected?.images.map(item => {
                                    return (<img className="ViewHotel-Photo" src={item.url} key={item.id} alt={item.id} />)
                                })}
                            </div>
                        </div>
                    </div>
                    {statusAPI === 201 || statusAPI === 200
                        ? <Snackbars openSnack={true} message={message} errors={null} statusCode={statusAPI} status={'success'} hideDuration={6000} />
                        : null}
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.hotels.loading,
        message: state.hotels.message,
        statusAPI: state.hotels.status,
        Features: state.lookups.features,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchFeatures: () => dispatch(fetchFeatures()),
        onChangeVerifyHotel: (ID, Status) => dispatch(changeVerifyHotel(ID, Status)),
        onResetHotels: () => dispatch(resetHotels())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHotel);