import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { fetchPermissions, fetchGroup, editGroup } from "../../../store/actions/groups";

import './EditRole.css';

const EditRole = (props) => {
    const { language, Closed, status, loading, group={}, styleDir, lang, GroupSelected, permissions = [], onFetchPermissions, onFetchGroup, onEditGroup } = props;

    const [permissionChecked, setPermissionChecked] = useState({});
    const [permissionsEditState, setPermissionsEditState] = useState([]);

    useEffect(() => {
        console.log('GroupSelected', GroupSelected);
        async function fetchData() {
            await onFetchGroup(GroupSelected.id);
            await onFetchPermissions();
            SetCheckedPermissions();
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (status === 201) {
            Closed();
        }
    }, [status]);

    const SetCheckedPermissions = () => {

        let permissionsEdit = group.permissions;
        let entries_permissionsEdit = Object.values(permissionsEdit);
  
        entries_permissionsEdit.map((item) => {
          return item.map((child) => {
            let childArr = permissionsEditState;
            childArr.push(child.id);
            setPermissionsEditState(childArr);
            return childArr;
          });
        });

        let objectChecked = {};

        let Arr = permissions.map((permission) => {
            return permission.map(item => {
                return { ...objectChecked, [item.id]: false };
            }
            );
        }
        );

        convertArray(Arr);
        setPermissionChecked(objectChecked);
    }


  const convertArray = (Arr) => {
    var newArr = [];

    for (var i = 0; i < Arr.length; i++) {
      newArr = newArr.concat(Arr[i]);
    }

    for (let j = 0; j < newArr.length; j++) {
      permissionsEditState.map((item) => {
        if (Object.keys(newArr[j]).toString() === item.toString()) {
          newArr[j] = { [Object.keys(newArr[j]).toString()]: true };
        }
      });
    }

    console.log("Final", newArr);

    setPermissionChecked(newArr);
    return newArr;
  };

    const validationSchema = yup.object({
        name: yup
            .string('Please enter a valid role name"')
            .required('Role name cannot be empty')
    });

    const formik = useFormik({
        initialValues: {
            name: GroupSelected?.name,
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            let permissionSubmit = [];
            for (let key in permissionChecked) {
              let objNew = permissionChecked[key];
              for(let aKey in objNew) {
                if(objNew[aKey] === true){
                  permissionSubmit.push(aKey);
                }
              }
            }

            let payload = {
                name: values.name,
                permissions: permissionSubmit
            };

            onEditGroup(payload, GroupSelected.id)
        },
    });

    let count = 0;

    const handleChange = (event) => {
        let checkedNow = {
            [event.target.name]: event.target.checked
        };

        setPermissionChecked({ ...permissionChecked, [event.target.id]: checkedNow });
    };

    return (
        <React.Fragment>
            <div className="EditRoleContainer">
                <div className="EditRole-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="EditRole-HeadTitle">{language.UsersManagement.usersManagement_edit_role}</Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="EditRoleLine"></div>
                <div className="EditRoleFormContainer">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="EditRoleFormContent">

                            <TextField
                                className={`EditRoleInput ${formik.touched.name && formik.errors.name ? 'EditRoleFormInputError' : ''}`}
                                id="name"
                                name="name"
                                label={<div>
                                    {language.UsersManagement.usersManagement_role_name}
                                    <span className="EditRoleInputRequired">*</span>
                                </div>}
                                variant="outlined"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={language.UsersManagement.usersManagement_role_name} />

                            {Object.values(permissions).map((permission, index) => {
                                console.log('kk',permissionChecked);
                                return (
                                    <div key={permission[0].group_name} className="boxs">
                                        <Typography component={'span'} variant={'body2'} className="EditRole-HeadPermissionTitle">{permission[0].group_name}</Typography>

                                        <div className="box-body">
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {permission.map(item => {

                                                        let CheckboxRender = (<FormControlLabel
                                                            key={item.id}
                                                            control={
                                                                <Checkbox
                                                                    checked={Object.values({ ...permissionChecked[count] }).toString() === 'true' ? true : false}
                                                                    onChange={handleChange}
                                                                    id={count}
                                                                    name={item.id}
                                                                />
                                                            }
                                                            label={item.display_name.en}
                                                        />);
                                                        count++;
                                                        return CheckboxRender;
                                                    })}
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                );
                            })
                            }

                        </div>

                        <div className="EditRoleFormFooter">
                            <Button disabled={loading} className="EditRoleEditRoleButton" type="submit">
                                {loading ? <SpinnerButton /> : language.UsersManagement.usersManagement_save}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.groups.loading,
        permissions: state.groups.permissions,
        status: state.groups.status,
        group: state.groups.group,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchPermissions: () => dispatch(fetchPermissions()),
        onFetchGroup: (payload) => dispatch(fetchGroup(payload)),
        onEditGroup: (payload, groupID) => dispatch(editGroup(payload, groupID)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRole));