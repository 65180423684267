import React, { useState, useEffect, useRef } from "react";

import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from '@material-ui/lab/Rating';

import MapMark from "../../assets/images/mapMark.png";

import "./GoogleMap.css";

const Marker = ({ children }) => children;

const GoogleMap = (props) => {
    const { Hotels, HotelSelected, language } = props;
 
    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(9);

    useEffect(() => {
        console.log('HotelSelected',HotelSelected);
        if(HotelSelected){
            mapRef.current.setZoom(20);
            mapRef.current.panTo({ lat: parseFloat(HotelSelected.lat), lng: parseFloat(HotelSelected.long) });
        }
    }, [HotelSelected]);

    const points = Hotels.map(hotel => ({
        type: "Feature",
        properties: { cluster: false, hotelId: hotel.id, category: hotel.type, hotelDetails: hotel },
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(hotel.long),
                parseFloat(hotel.lat)
            ]
        }
    }));

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });


    return (
        <div style={{ height: "80vh", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDKPw8w9TGfKK-xXWoiPUNDwlWfLg8oh7E' }}
                defaultCenter={{ lat: 21.42184037, lng: 39.82429285 }}
                defaultZoom={9}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
            >
                {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount
                    } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}`}
                                lat={latitude}
                                lng={longitude}
                            >
                                <div
                                    className="cluster-marker"
                                    style={{
                                        width: `${10 + (pointCount / points.length) * 20}px`,
                                        height: `${10 + (pointCount / points.length) * 20}px`
                                    }}
                                    onClick={() => {
                                        const expansionZoom = Math.min(
                                            supercluster.getClusterExpansionZoom(cluster.id),
                                            20
                                        );
                                        mapRef.current.setZoom(expansionZoom);
                                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                                    }}
                                >
                                    <span>{pointCount}</span>
                                    <span>{language.Hotels.hotels_hotel}</span>
                                    <img src={MapMark} alt="hotel marker" />
                                </div>
                            </Marker>
                        );
                    }

                    return (
                        <Marker
                            key={`hotel-${cluster.properties.hotelId}`}
                            lat={latitude}
                            lng={longitude}
                        >
                            <div className={`hotel-marker-container ${HotelSelected?.id === cluster.properties.hotelDetails.id ? 'hotel-selected' : ''}`}>
                                <img className={`hotel-marker-img ${HotelSelected?.id === cluster.properties.hotelDetails.id ? 'bounce' : ''}`} src={MapMark} alt="hotel marker" />
                                <div className="HotelMarker-HotelContainer">
                                    <div className="HotelMarker-HotelImg">
                                        <Skeleton variant="rect" width={90} height={115} />
                                    </div>
                                    <div className="HotelMarker-HotelContent">
                                        <Typography component={'span'} variant={'body2'} className="HotelMarker-HotelContent-Title">{cluster.properties.hotelDetails.facility_name}</Typography>
                                        <Typography component={'span'} variant={'body2'} className="HotelMarker-HotelContent-LocationTitle">
                                            <LocationOnIcon />
                                            {cluster.properties.hotelDetails.city} / {cluster.properties.hotelDetails.area}
                                        </Typography>
                                        <Rating name="read-only" value={cluster.properties.hotelDetails.stars_value} readOnly max={5} />
                                        <Typography component={'span'} variant={'body2'} className="HotelMarker-HotelContent-DistanceTitle">
                                            {'Distance from Haram'} <span className="HotelMarker-HotelContent-DistanceValue">{cluster.properties.hotelDetails.harm_distance}</span>
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Marker>
                    );
                })}
            </GoogleMapReact>
        </div>
    );
};

export default (GoogleMap);