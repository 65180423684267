import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { changePasswordUser } from "../../../store/actions/users";

import './ChangePassword.css';

const ChangePassword = (props) => {
    const { language, Closed, loading, styleDir, lang, IDSelected, onChangePasswordUser } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validationSchema = yup.object({
        password: yup
            .string('Password cannot be empty')
            .min(6, 'Password must be between 6 and 20 characters')
            .max(20, 'Password must be between 6 and 20 characters')
            .required('Password cannot be empty'),
        confirmPassword: yup
            .string('Confirm Password cannot be empty')
            .min(6, 'Confirm Password must be between 6 and 20 characters')
            .max(20, 'Confirm Password must be between 6 and 20 characters')
            .test({
                name: 'passwords-match',
                exclusive: false,
                params: {},
                message: 'Confirm password must match the password',
                test: function (value) {
                    return value === this.parent.password
                },
            })
            .required('Confirm Password cannot be empty'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {

            let payload = {
                admin_id: IDSelected,
                password: values.password,
                password_confirmation: values.confirmPassword,
            };

            onChangePasswordUser(payload);
        },
    });

    return (
        <React.Fragment>
            <div className="ChangePasswordContainer">
                <div className="ChangePassword-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="ChangePassword-HeadTitle">{language.UsersManagement.usersManagement_changePassword}</Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="ChangePasswordLine"></div>
                <div className="ChangePasswordFormContainer">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="ChangePasswordFormContent">
                            <FormControl
                                className={`ChangePasswordInput ${formik.touched.password && formik.errors.password ? 'NewBranchFormInputError' : ''}`}
                                variant="outlined">
                                <InputLabel htmlFor="filled-adornment-password">
                                    <div>
                                        {language.UsersManagement.usersManagement_newPassword}
                                        <span className="ChangePasswordInputRequired">*</span>
                                    </div>
                                </InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    placeholder={language.UsersManagement.usersManagement_newPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={<div>
                                        {language.UsersManagement.usersManagement_newPassword}
                                        <span className="ChangePasswordInputRequired">*</span>
                                    </div>}
                                />
                                {formik.touched.password && Boolean(formik.errors.password)
                                    ? <FormHelperText className={'PasswordInputError'} id="component-error-text">{formik.errors.password}</FormHelperText>
                                    : null}
                            </FormControl>

                            <FormControl
                                className={`ChangePasswordInput ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'NewBranchFormInputError' : ''}`}
                                variant="outlined">
                                <InputLabel htmlFor="filled-adornment-password">
                                    <div>
                                        {language.UsersManagement.usersManagement_confirmNewPassword}
                                        <span className="ChangePasswordInputRequired">*</span>
                                    </div>
                                </InputLabel>
                                <OutlinedInput
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    placeholder={language.UsersManagement.usersManagement_confirmNewPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={<div>
                                        {language.UsersManagement.usersManagement_confirmNewPassword}
                                        <span className="ChangePasswordInputRequired">*</span>
                                    </div>}
                                />
                                {formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                                    ? <FormHelperText className={'PasswordInputError'} id="component-error-text">{formik.errors.confirmPassword}</FormHelperText>
                                    : null}
                            </FormControl>

                        </div>

                        <div className="ChangePasswordFormFooter">
                            <Button disabled={loading} className="ChangePasswordChangePasswordButton" type="submit">
                                {loading ? <SpinnerButton /> : language.UsersManagement.usersManagement_changePassword}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.users.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePasswordUser: (payload) => dispatch(changePasswordUser(payload))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));