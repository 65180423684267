import axios from "../../service/axios";
import * as actionTypes from './actionTypes';

import { logout } from './auth';

export const fetchAreasStart = () => {
    return {
        type: actionTypes.FETCH_AREAS_START
    };
};

export const fetchAreasSuccess = (areas) => {
    return {
        type: actionTypes.FETCH_AREAS_SUCCESS,
        areas: areas
    };
};

export const fetchAreasFail = (error) => {
    return {
        type: actionTypes.FETCH_AREAS_FAIL,
        error: error
    };
};

export const fetchAreas = (payload) => {
    return (dispatch, getState) => {
        dispatch(fetchAreasStart());
        const url = "/list/areas/in/" + payload;
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchAreasSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchAreasFail(err.response.data.error));
                }
            });
    };
};

export const fetchAreasWithCity = (CityID) => {
    return (dispatch, getState) => {
        dispatch(fetchAreasStart());
        const url = "/lookups/areas?city_id=" + CityID;
        axios.get(url, {
            headers: {
                "Accept-Language": getState().language.lang.language
          }
          })
            .then(response => {
                console.log(response);
                dispatch(fetchAreasSuccess(response.data.data));
            })
            .catch(err => {
                console.log(err.response.data);
                if(err.response.status === 401){
                    dispatch(logout());
                }else{
                    dispatch(fetchAreasFail(err.response.data.error));
                }
            });
    };
};