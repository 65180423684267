import 'date-fns';
import enLocale from "date-fns/locale/en-US";
import arLocale from "date-fns/locale/ar-SA";
import moment from "moment";

import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImageUploading from "react-images-uploading";

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import GoogleMap from '../../GoogleMap/GoogleMap';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { fetchFacilityType } from "../../../store/actions/facilityType";
import { fetchCountry } from "../../../store/actions/country";
import { fetchCitiesWithCountry } from "../../../store/actions/cities";
import { fetchAreasWithCity } from "../../../store/actions/areas";
import { fetchStars, fetchFeatures } from "../../../store/actions/lookups";
import { addHotel } from "../../../store/actions/hotels";

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";
import Snackbars from '../../UI/Spinner/Spinner';

import './EditHotel.css';

const localeMap = {
    en: enLocale,
    ar: arLocale,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'} variant={'body2'} >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EditHotel = (props) => {
    const { loading, language, lang, styleDir, message, statusAPI, error, HotelSelected, FacilityType, Country, Features = [], Stars = [], Cities = [], Areas = [], onFetchFacilityType, onFetchCountry, onFetchCitiesWithCountry, onFetchAreasWithCity, onFetchStars, onFetchFeatures, onAddHotel } = props;

    const history = useHistory();

    const [expanded, setExpanded] = useState('panel1');
    const [locale, setLocale] = useState(language.language);

    const [value, setValue] = useState(0);

    const [images, setImages] = useState([]);
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    console.log('HotelSelected', HotelSelected);

    const [country, setCountry] = useState(HotelSelected?.country_id);
    const [city, setCity] = useState(HotelSelected?.city_id);
    const [area, setArea] = useState(HotelSelected?.area_id);
    const [facilityType, setFacilityType] = useState(HotelSelected?.type_id);

    const [facilityClassification, setFacilityClassification] = useState(HotelSelected?.classification_id);
    const [operatorGender, setOperatorGender] = useState(HotelSelected?.operator_gender);

    const [facilityClassificationStartDate, setFacilityClassificationStartDate] = useState(new Date(HotelSelected?.classification_start_date));
    const [facilityClassificationEndDate, setFacilityClassificationEndDate] = useState(new Date(HotelSelected?.classification_end_date));

    const [facilityLicensingStartDate, setFacilityLicensingStartDate] = useState(new Date(HotelSelected?.facility_licensing_start_date));

    const [featuresChecked, setFeaturesChecked] = useState({});

    const handleFacilityClassificationStartDateChange = (date) => {
        setFacilityClassificationStartDate(date);
        formik.setFieldValue("facilityClassificationStartDate", date);
    };

    const handleFacilityClassificationEndDateChange = (date) => {
        setFacilityClassificationEndDate(date);
        formik.setFieldValue("facilityClassificationEndDate", date);
    };

    const handleFacilityLicensingStartDateChange = (date) => {
        setFacilityLicensingStartDate(date);
        formik.setFieldValue("facilityLicensingStartDate", date);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeFacilityType = (event) => {
        setFacilityType(event.target.value.target.value);
    };

    const handleChangeCountry = (event) => {
        setCountry(event.target.value.target.value);
        onFetchCitiesWithCountry(event.target.value.target.value);
    };

    const handleChangeFacilityClassification = (event) => {
        setFacilityClassification(event.target.value.target.value);
    };

    const handleChangeOperatorGender = (event) => {
        setOperatorGender(event.target.value.target.value);
    };

    const handleChangeCity = (event) => {
        setCity(event.target.value.target.value);
        onFetchAreasWithCity(event.target.value.target.value);
    };

    const handleChangeArea = (event) => {
        setArea(event.target.value.target.value);
    };

    useEffect(() => {
        async function fetchData() {
            await onFetchFacilityType();
            await onFetchCountry();
            await onFetchStars();
            await onFetchFeatures();
            SetCheckedFeatures();
        }
        fetchData();
    }, []);

    useEffect(() => {
        if(statusAPI === 201){
            history.push(`/admin/hotels`);
        }
    }, [statusAPI]);

    const SetCheckedFeatures =  () => {
        console.log('jjj', HotelSelected?.features)
        console.log('jjj', Features)
        let objectChecked = {};
        let CheckedFeatures = Features?.map( (item) => {
                return { ...objectChecked, [item.id]: HotelSelected?.features.some((feature=>(item.id === feature.id))) };
        }
        );
         console.log('jjj', CheckedFeatures)
        setFeaturesChecked(CheckedFeatures);
    }

    const featuresHandleChange = (event) => {
        let checkedNow = {
            [event.target.name]: event.target.checked
        };

        setFeaturesChecked({ ...featuresChecked, [event.target.name]: checkedNow });
    };

    const validationSchema = yup.object({
        facilityType: yup.string().required('choose a facility Type').nullable(),
        country: yup.string().required('choose a country').nullable(),
        city: yup.string().required('choose a city').nullable(),
        area: yup.string().required('choose a area').nullable(),
        buildingNumber: yup.number('Please enter a valid building number'),
        street: yup.string('Please enter a street').required('Street cannot be empty'),
        district: yup.string('Please enter a district').required('District cannot be empty'),
        facilityPobox: yup.number('Please enter a valid Facility Pobox'),
        facilityPhone: yup.number()
            .typeError('Please enter a valid phone number')
            .test("phoneNumber-check", 'Please enter a valid phone number', (mobile) => {
                if (mobile) {
                    let mobilevalue = '+20' + mobile;

                    try {
                        const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
                        const number = phoneUtil.parse(mobilevalue.toString(), 'EG');

                        if (phoneUtil.isValidNumber(number, 'EG')) {
                            return true;
                        } else {
                            return false;
                        }
                    } catch (e) {
                        console.log('Valid Number was thrown: ', e.toString());
                    }
                }
            }),
        facilityEmail: yup
            .string('Please enter a valid email')
            .email('Please enter a valid email')
            .max(50, 'Please enter a valid email'),
        facilityClassification: yup.string().required('choose a facility classification type').nullable(),
        facilityClassificationStartDate: yup.string().required('Facility Classification Start Date cannot be empty').nullable(),
        facilityClassificationEndDate: yup.string().required('Facility Classification End Date cannot be empty').nullable(),
        locationEN: yup.string('Please enter location english').required('Location english cannot be empty'),
        placeNameEN: yup.string('Please enter place name english').required('Place name english cannot be empty'),
        facilityNameEN: yup.string('Please enter facility name english').required('Facility name english cannot be empty'),
        descriptionEN: yup.string('Please enter Description english'),
        locationAR: yup.string('Please enter location arabic').required('Location arabic cannot be empty'),
        placeNameAR: yup.string('Please enter place name arabic').required('Place name arabic cannot be empty'),
        facilityNameAR: yup.string('Please enter facility name arabic').required('Facility name arabic cannot be empty'),
        descriptionAR: yup.string('Please enter Description arabic'),
        hotelLat: yup.string('Please enter lat').required('Lat cannot be empty'),
        hotelLng: yup.string('Please enter long').required('Long cannot be empty'),
        haramLat: yup.string('Please enter lat').required('Lat cannot be empty'),
        haramLng: yup.string('Please enter long').required('Long cannot be empty'),
        pricingAmount: yup.number('Please enter a valid Pricing Amount'),
        renewalCount: yup.number('Please enter a valid Renewal Count'),
        floorsCount: yup.number('Please enter a valid Floors Count'),
        deluxeRoomsCount: yup.number('Please enter a valid Deluxe Rooms Count'),
        regularRoomsCount: yup.number('Please enter a valid Regular Rooms Count'),
        regularSuitesCount: yup.number('Please enter a valid Regular Suites Count'),
        deluxeSuitesCount: yup.number('Please enter a valid Deluxe Suites Count'),
        parkingCount: yup.number('Please enter a valid Parking Count'),
        facilityOwner: yup.string('Please enter Facility Owner').required('Facility Owner cannot be empty'),
        operatorName: yup.string('Please enter Operator Name').required('Operator Name cannot be empty'),
        operatorGender: yup.string().required('choose a Operator Gender').nullable(),
        licensingInvestor: yup.number('Please enter a valid Licensing Investor').required('Licensing Investor cannot be empty'),
        facilityLicensingStartDate: yup.string().required('Facility Licensing Start Date cannot be empty').nullable(),
    });

    const formik = useFormik({
        initialValues: {
            facilityType: '',
            country: '',
            city: '',
            area: '',
            buildingNumber: HotelSelected?.buildings_count,
            street: HotelSelected?.street,
            district: HotelSelected?.district,
            facilityPobox: HotelSelected?.pobox,
            facilityPhone: HotelSelected?.phone,
            facilityEmail: HotelSelected?.email,
            facilityClassification: '',
            facilityClassificationStartDate: facilityClassificationStartDate,
            facilityClassificationEndDate: facilityClassificationEndDate,
            locationEN: HotelSelected?.en_location,
            placeNameEN: HotelSelected?.en_place_name,
            facilityNameEN: HotelSelected?.en_facility_name,
            descriptionEN: HotelSelected?.en_description,
            locationAR: HotelSelected?.ar_location,
            placeNameAR: HotelSelected?.ar_place_name,
            facilityNameAR: HotelSelected?.ar_facility_name,
            descriptionAR: HotelSelected?.ar_description,
            hotelLat: HotelSelected?.lat,
            hotelLng: HotelSelected?.long,
            haramLat: HotelSelected?.harm_lat,
            haramLng: HotelSelected?.harm_long,
            harmDistance: HotelSelected?.harm_distance,
            pricingAmount: HotelSelected?.pricing_amount,
            renewalCount: HotelSelected?.renewal_count,
            floorsCount: HotelSelected?.floors_count,
            deluxeRoomsCount: HotelSelected?.deluxe_rooms_count,
            regularRoomsCount: HotelSelected?.regular_rooms_count,
            regularSuitesCount: HotelSelected?.regular_suites_count,
            deluxeSuitesCount: HotelSelected?.deluxe_suites_count,
            parkingCount: HotelSelected?.parking_count,
            facilityOwner: HotelSelected?.owner,
            operatorName: HotelSelected?.operator,
            operatorGender: HotelSelected?.operator_gender,
            licensingInvestor: HotelSelected?.licensing_number_for_investor,
            facilityLicensingStartDate: facilityLicensingStartDate
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {

            let featuresSubmit = [];
            console.log('featuresChecked', featuresChecked);
            for (let key in featuresChecked) {
                console.log('key', key);
                featuresSubmit.push(Number(key));
            }

            const formData = new FormData();

            formData.append(`type_id`, values.facilityType);
            formData.append(`country_id`, values.country);
            formData.append(`city_id`, values.city);
            formData.append(`area_id`, values.area);
            formData.append(`buildings_count`, values.buildingNumber);
            formData.append(`street`, values.street);
            formData.append(`district`, values.district);
            formData.append(`pobox`, values.facilityPobox);
            formData.append(`phone`, values.facilityPhone);
            formData.append(`email`, values.facilityEmail);
            formData.append(`classification_id`, values.facilityClassification);
            formData.append(`classification_start_date`, moment(facilityClassificationStartDate).format("YYYY-MM-DD"));
            formData.append(`classification_end_date`, moment(facilityClassificationEndDate).format("YYYY-MM-DD"));
            formData.append(`en_location`, values.locationEN);
            formData.append(`en_place_name`, values.placeNameEN);
            formData.append(`en_facility_name`, values.facilityNameEN);
            formData.append(`en_description`, values.descriptionEN);
            formData.append(`ar_location`, values.locationAR);
            formData.append(`ar_place_name`, values.placeNameAR);
            formData.append(`ar_facility_name`, values.facilityNameAR);
            formData.append(`ar_description`, values.descriptionAR);
            formData.append(`lat`, values.hotelLat);
            formData.append(`long`, values.hotelLng);
            formData.append(`harm_lat`, values.haramLat);
            formData.append(`harm_long`, values.haramLng);
            formData.append(`pricing_amount`, values.pricingAmount);
            formData.append(`renewal_count`, values.renewalCount);
            formData.append(`floors_count`, values.floorsCount);
            formData.append(`deluxe_rooms_count`, values.deluxeRoomsCount);
            formData.append(`regular_rooms_count`, values.regularRoomsCount);
            formData.append(`regular_suites_count`, values.regularSuitesCount);
            formData.append(`deluxe_suites_count`, values.deluxeSuitesCount);
            formData.append(`parking_count`, values.parkingCount);
            formData.append(`owner`, values.facilityOwner);
            formData.append(`operator`, values.operatorName);

            formData.append(`operator_gender`, values.operatorGender);
            formData.append(`licensing_number_for_investor`, values.licensingInvestor);
            formData.append(`facility_licensing_start_date`, moment(facilityLicensingStartDate).format("YYYY-MM-DD"));

            for (let i = 0; i < featuresSubmit.length; i++) {
                formData.append(`features[${i}]`, featuresSubmit[i]);
            }

            for (let i = 0; i < images.length; i++) {
                formData.append(`images[${i}]`, images[i].file);
            }

            onAddHotel(formData);
        },
    });

    const Gender = [{
        value: 'male',
        title: 'Male'
    }, {
        value: 'female',
        title: 'Female'
    }
    ];

    return (
        <div className="EditHotel-Container">
            <div className="EditHotel-HeadContainer">
                <Typography component={'span'} variant={'body2'} className="EditHotel-HeadTitle">{language.Hotels.hotels_edit_facility}</Typography>
            </div>

            <div className={`EditHotel-Content`}>
                <form onSubmit={formik.handleSubmit}>

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={`EditHotel-AccordionHeadTitle`} >{language.Hotels.hotels_details}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="EditHotel-FormDetailsContainer">
                            <div className="EditHotel-FormRow">
                                <FormControl
                                    className={`EditHotel-Input EditHotel-SelectInput ${formik.touched.facilityType && formik.errors.facilityType ? 'EditHotel-FormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                        {language.Hotels.hotels_facility_type}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={facilityType}
                                        name="facilityType"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'facilityType', value: selectedOption } }
                                            handleChangeFacilityType(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.Hotels.hotels_facility_type}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                            {language.Hotels.hotels_choose_facility_type}
                                        </MenuItem>
                                        {FacilityType.map(type => (
                                            <MenuItem key={type.id} dir={styleDir} lang={lang} value={type.id}>{type.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.facilityType && Boolean(formik.errors.facilityType)
                                        ? <FormHelperText className={'EditHotel-FormInputError'} id="component-error-text">{formik.errors.facilityType}</FormHelperText>
                                        : null}
                                </FormControl>

                                <FormControl
                                    className={`EditHotel-Input EditHotel-SelectInput ${formik.touched.country && formik.errors.country ? 'EditHotel-FormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                            {language.Hotels.hotels_country}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={country}
                                        name="country"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'country', value: selectedOption } }
                                            handleChangeCountry(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.Hotels.hotels_country}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                            {language.Hotels.hotels_choose_country}
                                        </MenuItem>
                                        {Country.map(countryItem => (
                                            <MenuItem key={countryItem.id} dir={styleDir} lang={lang} value={countryItem.id}>{countryItem.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.country && Boolean(formik.errors.country)
                                        ? <FormHelperText className={'EditHotel-FormInputError'} id="component-error-text">{formik.errors.country}</FormHelperText>
                                        : null}
                                </FormControl>

                                <FormControl
                                    className={`EditHotel-Input EditHotel-SelectInput ${formik.touched.city && formik.errors.city ? 'EditHotel-FormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                            {language.Hotels.hotels_city}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={city}
                                        name="city"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'city', value: selectedOption } }
                                            handleChangeCity(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.Hotels.hotels_city}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                            {language.Hotels.hotels_choose_city}
                                        </MenuItem>
                                        {Cities.map(cityItem => (
                                            <MenuItem key={cityItem.id} dir={styleDir} lang={lang} value={cityItem.id}>{cityItem.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.city && Boolean(formik.errors.city)
                                        ? <FormHelperText className={'EditHotel-FormInputError'} id="component-error-text">{formik.errors.city}</FormHelperText>
                                        : null}
                                </FormControl>

                                <FormControl
                                    className={`EditHotel-Input EditHotel-SelectInput ${formik.touched.area && formik.errors.area ? 'EditHotel-FormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                            {language.Hotels.hotels_area}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={area}
                                        name="area"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'area', value: selectedOption } }
                                            handleChangeArea(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.Hotels.hotels_area}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                            {language.Hotels.hotels_choose_area}
                                        </MenuItem>
                                        {Areas.map(areaItem => (
                                            <MenuItem key={areaItem.id} dir={styleDir} lang={lang} value={areaItem.id}>{areaItem.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.area && Boolean(formik.errors.area)
                                        ? <FormHelperText className={'EditHotel-FormInputError'} id="component-error-text">{formik.errors.area}</FormHelperText>
                                        : null}
                                </FormControl>
                            </div>
                            <div className="EditHotel-FormRow">
                                <TextField
                                    className={`EditHotel-Input EditHotel-BuildingNumberInput ${formik.touched.buildingNumber && formik.errors.buildingNumber ? 'EditHotel-FormInputError' : ''}`}
                                    id="buildingNumber"
                                    name="buildingNumber"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_building_number}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.buildingNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.buildingNumber && Boolean(formik.errors.buildingNumber)}
                                    helperText={formik.touched.buildingNumber && formik.errors.buildingNumber}
                                    placeholder={language.Hotels.hotels_building_number} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-StreetInput ${formik.touched.street && formik.errors.street ? 'EditHotel-FormInputError' : ''}`}
                                    id="street"
                                    name="street"
                                    label={<div>
                                        {language.Hotels.hotels_street}
                                        <span className="EditHotel-InputRequired">*</span>
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.street}
                                    onChange={formik.handleChange}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    helperText={formik.touched.street && formik.errors.street}
                                    placeholder={language.Hotels.hotels_street} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-DistrictInput ${formik.touched.district && formik.errors.district ? 'EditHotel-FormInputError' : ''}`}
                                    id="district"
                                    name="district"
                                    label={<div>
                                        {language.Hotels.hotels_district}
                                        <span className="EditHotel-InputRequired">*</span>
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.district}
                                    onChange={formik.handleChange}
                                    error={formik.touched.district && Boolean(formik.errors.district)}
                                    helperText={formik.touched.district && formik.errors.district}
                                    placeholder={language.Hotels.hotels_district} />
                            </div>
                            <div className="EditHotel-FormRow">
                                <TextField
                                    className={`EditHotel-Input EditHotel-FacilityPoboxInput ${formik.touched.facilityPobox && formik.errors.facilityPobox ? 'EditHotel-FormInputError' : ''}`}
                                    id="facilityPobox"
                                    name="facilityPobox"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_facilityPobox}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.facilityPobox}
                                    onChange={formik.handleChange}
                                    error={formik.touched.facilityPobox && Boolean(formik.errors.facilityPobox)}
                                    helperText={formik.touched.facilityPobox && formik.errors.facilityPobox}
                                    placeholder={language.Hotels.hotels_facilityPobox} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-FacilityPhoneInput ${formik.touched.facilityPhone && formik.errors.facilityPhone ? 'EditHotel-FormInputError' : ''}`}
                                    id="facilityPhone"
                                    name="facilityPhone"
                                    label={<div>
                                         {language.Hotels.hotels_facilityPhone}
                                    </div>}
                                    variant="outlined"
                                    inputProps={{ maxLength: 11 }}
                                    value={formik.values.facilityPhone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.facilityPhone && Boolean(formik.errors.facilityPhone)}
                                    helperText={formik.touched.facilityPhone && formik.errors.facilityPhone}
                                    placeholder={language.Hotels.hotels_facilityPhone} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-FacilityEmailInput ${formik.touched.facilityEmail && formik.errors.facilityEmail ? 'EditHotel-FormInputError' : ''}`}
                                    id="facilityEmail"
                                    name="facilityEmail"
                                    label={<div>
                                         {language.Hotels.hotels_facilityEmail}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.facilityEmail}
                                    onChange={formik.handleChange}
                                    error={formik.touched.facilityEmail && Boolean(formik.errors.facilityEmail)}
                                    helperText={formik.touched.facilityEmail && formik.errors.facilityEmail}
                                    placeholder= {language.Hotels.hotels_facilityEmail} />
                            </div>
                            <div className="EditHotel-FormRow">
                                <FormControl
                                    className={`EditHotel-Input EditHotel-FacilityClassificationInput EditHotel-SelectInput ${formik.touched.facilityClassification && formik.errors.facilityClassification ? 'EditHotel-FormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                        {language.Hotels.hotels_facilityClassification_type}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={facilityClassification}
                                        name="facilityClassification"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'facilityClassification', value: selectedOption } }
                                            handleChangeFacilityClassification(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.Hotels.hotels_facilityClassification_type}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                        {language.Hotels.hotels_choose_facilityClassification_type}
                                        </MenuItem>
                                        {Stars.map(starsItem => (
                                            <MenuItem key={starsItem.id} dir={styleDir} lang={lang} value={starsItem.id}>{starsItem.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.facilityClassification && Boolean(formik.errors.facilityClassification)
                                        ? <FormHelperText className={'EditHotel-FormInputError'} id="component-error-text">{formik.errors.facilityClassification}</FormHelperText>
                                        : null}
                                </FormControl>

                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        name="facilityClassificationStartDate"
                                        className={`EditHotel-Input EditHotel-FacilityClassificationStartDateInput ${formik.touched.facilityClassificationStartDate && formik.errors.facilityClassificationStartDate ? 'EditHotel-FormInputError' : ''}`}
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label={<div>
                                            {language.Hotels.hotels_facilityClassification_startDate}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>}
                                        value={facilityClassificationStartDate}
                                        helperText={formik.errors.facilityClassificationStartDate}
                                        onChange={handleFacilityClassificationStartDateChange}
                                        error={formik.touched.facilityClassificationStartDate && Boolean(formik.errors.facilityClassificationStartDate)}
                                        KeyboardButtonProps={{
                                            'aria-label': language.Hotels.hotels_facilityClassification_startDate,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        name="facilityClassificationEndDate"
                                        className={`EditHotel-Input EditHotel-FacilityClassificationEndDateInput ${formik.touched.facilityClassificationEndDate && formik.errors.facilityClassificationEndDate ? 'EditHotel-FormInputError' : ''}`}
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label={<div>
                                            {language.Hotels.hotels_facilityClassification_endDate}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>}
                                        value={facilityClassificationEndDate}
                                        helperText={formik.errors.facilityClassificationEndDate}
                                        onChange={handleFacilityClassificationEndDateChange}
                                        error={formik.touched.facilityClassificationEndDate && Boolean(formik.errors.facilityClassificationEndDate)}
                                        KeyboardButtonProps={{
                                            'aria-label': language.Hotels.hotels_facilityClassification_endDate,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                            </div>
                            <div className="EditHotel-FormRow">
                                <Box className="EditHotel-BoxTabs" sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs className="EditHotel-BoxTabs-Tabs" value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                                            <Tab className="EditHotel-BoxTabs-Tab" label={language.Hotels.hotels_english} {...a11yProps(0)} />
                                            <Tab className="EditHotel-BoxTabs-Tab" label={language.Hotels.hotels_arabic} {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel className="EditHotel-TabPanel-Container" value={value} index={0}>
                                        <div className="EditHotel-FormRow">
                                            <TextField
                                                className={`EditHotel-Input EditHotel-FacilityNameENInput ${formik.touched.facilityNameEN && formik.errors.facilityNameEN ? 'EditHotel-FormInputError' : ''}`}
                                                id="facilityNameEN"
                                                name="facilityNameEN"
                                                label={<div>
                                                    {language.Hotels.hotels_facility_name}
                                                    <span className="EditHotel-InputRequired">*</span>
                                                </div>}
                                                variant="outlined"
                                                value={formik.values.facilityNameEN}
                                                onChange={formik.handleChange}
                                                error={formik.touched.facilityNameEN && Boolean(formik.errors.facilityNameEN)}
                                                helperText={formik.touched.facilityNameEN && formik.errors.facilityNameEN}
                                                placeholder={language.Hotels.hotels_facility_name} />

                                            <TextField
                                                className={`EditHotel-Input EditHotel-PlaceNameENInput ${formik.touched.placeNameEN && formik.errors.placeNameEN ? 'EditHotel-FormInputError' : ''}`}
                                                id="placeNameEN"
                                                name="placeNameEN"
                                                label={<div>
                                                    {language.Hotels.hotels_placeName}
                                                    <span className="EditHotel-InputRequired">*</span>
                                                </div>}
                                                variant="outlined"
                                                value={formik.values.placeNameEN}
                                                onChange={formik.handleChange}
                                                error={formik.touched.placeNameEN && Boolean(formik.errors.placeNameEN)}
                                                helperText={formik.touched.placeNameEN && formik.errors.placeNameEN}
                                                placeholder={language.Hotels.hotels_placeName} />

                                            <TextField
                                                className={`EditHotel-Input EditHotel-LocationENInput ${formik.touched.locationEN && formik.errors.locationEN ? 'EditHotel-FormInputError' : ''}`}
                                                id="locationEN"
                                                name="locationEN"
                                                label={<div>
                                                    {language.Hotels.hotels_location}
                                                    <span className="EditHotel-InputRequired">*</span>
                                                </div>}
                                                variant="outlined"
                                                value={formik.values.locationEN}
                                                onChange={formik.handleChange}
                                                error={formik.touched.locationEN && Boolean(formik.errors.locationEN)}
                                                helperText={formik.touched.locationEN && formik.errors.locationEN}
                                                placeholder={language.Hotels.hotels_location} />
                                        </div>
                                        <div className="EditHotel-FormRow">
                                            <TextField
                                                className={`EditHotel-Input EditHotel-DescriptionENInput ${formik.touched.descriptionEN && formik.errors.descriptionEN ? 'EditHotel-FormInputError' : ''}`}
                                                id="descriptionEN"
                                                name="descriptionEN"
                                                label={<div>
                                                    {language.Hotels.hotels_description}
                                                </div>}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                value={formik.values.descriptionEN}
                                                onChange={formik.handleChange}
                                                error={formik.touched.descriptionEN && Boolean(formik.errors.descriptionEN)}
                                                helperText={formik.touched.descriptionEN && formik.errors.descriptionEN}
                                                placeholder={language.Hotels.hotels_description} />
                                        </div>
                                    </TabPanel>
                                    <TabPanel className="EditHotel-TabPanel-Container" value={value} index={1}>
                                        <div className="EditHotel-FormRow">
                                            <TextField
                                                className={`EditHotel-Input EditHotel-FacilityNameARInput ${formik.touched.facilityNameAR && formik.errors.facilityNameAR ? 'EditHotel-FormInputError' : ''}`}
                                                id="facilityNameAR"
                                                name="facilityNameAR"
                                                label={<div>
                                                    {language.Hotels.hotels_facility_name}
                                                    <span className="EditHotel-InputRequired">*</span>
                                                </div>}
                                                variant="outlined"
                                                value={formik.values.facilityNameAR}
                                                onChange={formik.handleChange}
                                                error={formik.touched.facilityNameAR && Boolean(formik.errors.facilityNameAR)}
                                                helperText={formik.touched.facilityNameAR && formik.errors.facilityNameAR}
                                                placeholder={language.Hotels.hotels_facility_name}/>

                                            <TextField
                                                className={`EditHotel-Input EditHotel-PlaceNameARInput ${formik.touched.placeNameAR && formik.errors.placeNameAR ? 'EditHotel-FormInputError' : ''}`}
                                                id="placeNameAR"
                                                name="placeNameAR"
                                                label={<div>
                                                    {language.Hotels.hotels_placeName}
                                                    <span className="EditHotel-InputRequired">*</span>
                                                </div>}
                                                variant="outlined"
                                                value={formik.values.placeNameAR}
                                                onChange={formik.handleChange}
                                                error={formik.touched.placeNameAR && Boolean(formik.errors.placeNameAR)}
                                                helperText={formik.touched.placeNameAR && formik.errors.placeNameAR}
                                                placeholder={language.Hotels.hotels_placeName} />

                                            <TextField
                                                className={`EditHotel-Input EditHotel-LocationARInput ${formik.touched.locationAR && formik.errors.locationAR ? 'EditHotel-FormInputError' : ''}`}
                                                id="locationAR"
                                                name="locationAR"
                                                label={<div>
                                                    {language.Hotels.hotels_location}
                                                    <span className="EditHotel-InputRequired">*</span>
                                                </div>}
                                                variant="outlined"
                                                value={formik.values.locationAR}
                                                onChange={formik.handleChange}
                                                error={formik.touched.locationAR && Boolean(formik.errors.locationAR)}
                                                helperText={formik.touched.locationAR && formik.errors.locationAR}
                                                placeholder={language.Hotels.hotels_location} />
                                        </div>
                                        <div className="EditHotel-FormRow">
                                            <TextField
                                                className={`EditHotel-Input EditHotel-DescriptionARInput ${formik.touched.descriptionAR && formik.errors.descriptionAR ? 'EditHotel-FormInputError' : ''}`}
                                                id="descriptionAR"
                                                name="descriptionAR"
                                                label={<div>
                                                    {language.Hotels.hotels_description}
                                                </div>}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                value={formik.values.descriptionAR}
                                                onChange={formik.handleChange}
                                                error={formik.touched.descriptionAR && Boolean(formik.errors.descriptionAR)}
                                                helperText={formik.touched.descriptionAR && formik.errors.descriptionAR}
                                                placeholder={language.Hotels.hotels_description} />
                                        </div>
                                    </TabPanel>
                                </Box>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={`EditHotel-AccordionHeadTitle`} >{language.Hotels.hotels_mapLocation}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="EditHotel-FormDetailsContainer">
                            <div className="EditHotel-FormRow">
                                <div className="EditHotel-HotelCoordinatesColumn">
                                    <div className="EditHotel-HotelCoordinatesContainer">
                                        <div className="EditHotel-HotelCoordinatesHead">
                                            <Typography className={`EditHotel-HotelCoordinatesHeadTitle`} >{language.Hotels.hotels_hotelCoordinates}</Typography>
                                            <LocationOnIcon className="EditHotel-HotelCoordinatesMarker" />
                                        </div>
                                        <TextField
                                            className={`EditHotel-Input EditHotel-HotelLatInput ${formik.touched.hotelLat && formik.errors.hotelLat ? 'EditHotel-FormInputError' : ''}`}
                                            id="hotelLat"
                                            name="hotelLat"
                                            label={<div>
                                                {language.Hotels.hotels_coordinates_lat}
                                                <span className="EditHotel-InputRequired">*</span>
                                            </div>}
                                            variant="outlined"
                                            value={formik.values.hotelLat}
                                            onChange={formik.handleChange}
                                            error={formik.touched.hotelLat && Boolean(formik.errors.hotelLat)}
                                            helperText={formik.touched.hotelLat && formik.errors.hotelLat}
                                            placeholder={language.Hotels.hotels_coordinates_lat} />

                                        <TextField
                                            className={`EditHotel-Input EditHotel-HotelLngInput ${formik.touched.hotelLng && formik.errors.hotelLng ? 'EditHotel-FormInputError' : ''}`}
                                            id="hotelLng"
                                            name="hotelLng"
                                            label={<div>
                                                {language.Hotels.hotels_coordinates_long}
                                                <span className="EditHotel-InputRequired">*</span>
                                            </div>}
                                            variant="outlined"
                                            value={formik.values.hotelLng}
                                            onChange={formik.handleChange}
                                            error={formik.touched.hotelLng && Boolean(formik.errors.hotelLng)}
                                            helperText={formik.touched.hotelLng && formik.errors.hotelLng}
                                            placeholder={language.Hotels.hotels_coordinates_long} />
                                    </div>


                                    <div className="EditHotel-HotelHarmCoordinatesContainer">
                                        <div className="EditHotel-HotelCoordinatesHead">
                                            <Typography className={`EditHotel-HotelCoordinatesHeadTitle`} >{'Haram Doors Coordinates'}</Typography>
                                            <LocationOnIcon className="EditHotel-HotelHaramCoordinatesMarker" />
                                        </div>
                                        <TextField
                                            className={`EditHotel-Input EditHotel-HotelLatInput ${formik.touched.haramLat && formik.errors.haramLat ? 'EditHotel-FormInputError' : ''}`}
                                            id="haramLat"
                                            name="haramLat"
                                            label={<div>
                                                {language.Hotels.hotels_coordinates_lat}
                                                <span className="EditHotel-InputRequired">*</span>
                                            </div>}
                                            variant="outlined"
                                            value={formik.values.haramLat}
                                            onChange={formik.handleChange}
                                            error={formik.touched.haramLat && Boolean(formik.errors.haramLat)}
                                            helperText={formik.touched.haramLat && formik.errors.haramLat}
                                            placeholder={language.Hotels.hotels_coordinates_lat} />

                                        <TextField
                                            className={`EditHotel-Input EditHotel-HotelLngInput ${formik.touched.haramLng && formik.errors.haramLng ? 'EditHotel-FormInputError' : ''}`}
                                            id="haramLng"
                                            name="haramLng"
                                            label={<div>
                                                {language.Hotels.hotels_coordinates_long}
                                                <span className="EditHotel-InputRequired">*</span>
                                            </div>}
                                            variant="outlined"
                                            value={formik.values.haramLng}
                                            onChange={formik.handleChange}
                                            error={formik.touched.haramLng && Boolean(formik.errors.haramLng)}
                                            helperText={formik.touched.haramLng && formik.errors.haramLng}
                                            placeholder={language.Hotels.hotels_coordinates_long} />
                                    </div>

                                    <TextField
                                    className={`EditHotel-Input EditHotel-HotelLngInput ${formik.touched.harmDistance && formik.errors.harmDistance ? 'EditHotel-FormInputError' : ''}`}
                                    id="harmDistance"
                                    name="harmDistance"
                                    label={<div>
                                        {language.Hotels.hotels_distance_haramDoor}
                                    </div>}
                                    variant="outlined"
                                    disabled={true}
                                    value={HotelSelected?.harm_distance}
                                    onChange={formik.handleChange}
                                    error={formik.touched.harmDistance && Boolean(formik.errors.harmDistance)}
                                    helperText={formik.touched.harmDistance && formik.errors.harmDistance}
                                    placeholder={language.Hotels.hotels_distance_haramDoor} />
                                </div>
                                <div className="EditHotel-MapContainer">
                                    {/* {formik.values.hotelLng !== '' && formik.values.hotelLat !== '' ? <GoogleMap Hotels={[{
                                        id: 1,
                                        lng: formik.values.hotelLng,
                                        lat: formik.values.hotelLat,
                                        type: "Hotel",
                                        facility_name: '',
                                        city: '',
                                        area: '',
                                        stars_value: '',
                                        harm_distance: ''
                                    }]} HotelSelected={null} /> : null} */}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={`EditHotel-AccordionHeadTitle`}>{language.Hotels.hotels_rooms}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="EditHotel-FormDetailsContainer">
                            <div className="EditHotel-FormRow">
                                <TextField
                                    className={`EditHotel-Input EditHotel-PricingAmountInput ${formik.touched.pricingAmount && formik.errors.pricingAmount ? 'EditHotel-FormInputError' : ''}`}
                                    id="pricingAmount"
                                    name="pricingAmount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_pricingAmount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.pricingAmount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.pricingAmount && Boolean(formik.errors.pricingAmount)}
                                    helperText={formik.touched.pricingAmount && formik.errors.pricingAmount}
                                    placeholder={language.Hotels.hotels_pricingAmount} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-RenewalCountInput ${formik.touched.renewalCount && formik.errors.renewalCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="renewalCount"
                                    name="renewalCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_renewalCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.renewalCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.renewalCount && Boolean(formik.errors.renewalCount)}
                                    helperText={formik.touched.renewalCount && formik.errors.renewalCount}
                                    placeholder={language.Hotels.hotels_renewalCount} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-FloorsCountInput ${formik.touched.floorsCount && formik.errors.floorsCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="floorsCount"
                                    name="floorsCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_floorsCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.floorsCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.floorsCount && Boolean(formik.errors.floorsCount)}
                                    helperText={formik.touched.floorsCount && formik.errors.floorsCount}
                                    placeholder={language.Hotels.hotels_floorsCount} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-DeluxeRoomsCountInput ${formik.touched.deluxeRoomsCount && formik.errors.deluxeRoomsCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="deluxeRoomsCount"
                                    name="deluxeRoomsCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_deluxeRoomsCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.deluxeRoomsCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.deluxeRoomsCount && Boolean(formik.errors.deluxeRoomsCount)}
                                    helperText={formik.touched.deluxeRoomsCount && formik.errors.deluxeRoomsCount}
                                    placeholder={language.Hotels.hotels_deluxeRoomsCount} />

                            </div>
                            <div className="EditHotel-FormRow">
                                <TextField
                                    className={`EditHotel-Input EditHotel-RegularRoomsCountInput ${formik.touched.regularRoomsCount && formik.errors.regularRoomsCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="regularRoomsCount"
                                    name="regularRoomsCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_regularRoomsCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.regularRoomsCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.regularRoomsCount && Boolean(formik.errors.regularRoomsCount)}
                                    helperText={formik.touched.regularRoomsCount && formik.errors.regularRoomsCount}
                                    placeholder={language.Hotels.hotels_regularRoomsCount} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-RegularSuitesCountInput ${formik.touched.regularSuitesCount && formik.errors.regularSuitesCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="regularSuitesCount"
                                    name="regularSuitesCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_regularSuitesCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.regularSuitesCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.regularSuitesCount && Boolean(formik.errors.regularSuitesCount)}
                                    helperText={formik.touched.regularSuitesCount && formik.errors.regularSuitesCount}
                                    placeholder={language.Hotels.hotels_regularSuitesCount} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-DeluxeSuitesCountInput ${formik.touched.deluxeSuitesCount && formik.errors.deluxeSuitesCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="deluxeSuitesCount"
                                    name="deluxeSuitesCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_deluxeSuitesCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.deluxeSuitesCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.deluxeSuitesCount && Boolean(formik.errors.deluxeSuitesCount)}
                                    helperText={formik.touched.deluxeSuitesCount && formik.errors.deluxeSuitesCount}
                                    placeholder={language.Hotels.hotels_deluxeSuitesCount} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-ParkingCountInput ${formik.touched.parkingCount && formik.errors.parkingCount ? 'EditHotel-FormInputError' : ''}`}
                                    id="parkingCount"
                                    name="parkingCount"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_parkingCount}
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.parkingCount}
                                    onChange={formik.handleChange}
                                    error={formik.touched.parkingCount && Boolean(formik.errors.parkingCount)}
                                    helperText={formik.touched.parkingCount && formik.errors.parkingCount}
                                    placeholder={language.Hotels.hotels_parkingCount} />

                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={`EditHotel-AccordionHeadTitle`} >{language.Hotels.hotels_facilities}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="EditHotel-FormDetailsContainer">
                            <FormControl component="fieldset">
                                <FormGroup className="EditHotel-FeaturesContainer">
                                {console.log('featuresChecked', featuresChecked)}
                                    {Features.map(item => {
                                        let CheckboxRender = (<FormControlLabel
                                            className="EditHotel-FeaturesContent-Checkbox"
                                            key={item.id}
                                            control={
                                                <Checkbox
                                                    checked={Object.values({ ...featuresChecked[item.id] }).toString() === 'true' ? true : false}
                                                    onChange={featuresHandleChange}
                                                    id={item.id}
                                                    name={item.id}
                                                />
                                            }
                                            label={item.name}
                                        />);
                                        return CheckboxRender;
                                    })}
                                </FormGroup>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={`EditHotel-AccordionHeadTitle`}>{language.Hotels.hotels_information}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="EditHotel-FormDetailsContainer">
                            <div className="EditHotel-FormRow">
                                <TextField
                                    className={`EditHotel-Input EditHotel-FacilityOwnerInput ${formik.touched.facilityOwner && formik.errors.facilityOwner ? 'EditHotel-FormInputError' : ''}`}
                                    id="facilityOwner"
                                    name="facilityOwner"
                                    label={<div>
                                        {language.Hotels.hotels_facilityOwner}
                                        <span className="EditHotel-InputRequired">*</span>
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.facilityOwner}
                                    onChange={formik.handleChange}
                                    error={formik.touched.facilityOwner && Boolean(formik.errors.facilityOwner)}
                                    helperText={formik.touched.facilityOwner && formik.errors.facilityOwner}
                                    placeholder={language.Hotels.hotels_facilityOwner} />

                                <TextField
                                    className={`EditHotel-Input EditHotel-OperatorNameInput ${formik.touched.operatorName && formik.errors.operatorName ? 'EditHotel-FormInputError' : ''}`}
                                    id="operatorName"
                                    name="operatorName"
                                    label={<div>
                                        {language.Hotels.hotels_operatorName}
                                        <span className="EditHotel-InputRequired">*</span>
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.operatorName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.operatorName && Boolean(formik.errors.operatorName)}
                                    helperText={formik.touched.operatorName && formik.errors.operatorName}
                                    placeholder={language.Hotels.hotels_operatorName} />

                                <FormControl
                                    className={`EditHotel-Input EditHotel-OperatorGenderInput EditHotel-SelectInput ${formik.touched.operatorGender && formik.errors.operatorGender ? 'EditHotel-FormInputError' : ''}`}
                                    variant="outlined" >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                        <div>
                                        {language.Hotels.hotels_operatorGender}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>
                                    </InputLabel>
                                    <Select
                                        dir={styleDir}
                                        lang={lang}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={operatorGender}
                                        name="operatorGender"
                                        onChange={selectedOption => {
                                            let event = { target: { name: 'operatorGender', value: selectedOption } }
                                            handleChangeOperatorGender(event);
                                            formik.handleChange(selectedOption);
                                        }}
                                        label={<div>
                                            {language.Hotels.hotels_operatorGender}
                                        </div>}
                                    >
                                        <MenuItem dir={styleDir} lang={lang} value="unset" selected disabled hidden>
                                        {language.Hotels.hotels_choose_operatorGender}
                                        </MenuItem>
                                        {Gender.map(genderItem => (
                                            <MenuItem key={genderItem.value} dir={styleDir} lang={lang} value={genderItem.value}>{genderItem.title}</MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.operatorGender && Boolean(formik.errors.operatorGender)
                                        ? <FormHelperText className={'EditHotel-FormInputError'} id="component-error-text">{formik.errors.operatorGender}</FormHelperText>
                                        : null}
                                </FormControl>
                            </div>
                            <div className="EditHotel-FormRow">
                                <TextField
                                    className={`EditHotel-Input EditHotel-LicensingInvestorInput ${formik.touched.licensingInvestor && formik.errors.licensingInvestor ? 'EditHotel-FormInputError' : ''}`}
                                    id="licensingInvestor"
                                    name="licensingInvestor"
                                    type="number"
                                    label={<div>
                                        {language.Hotels.hotels_licensingInvestor}
                                        <span className="EditHotel-InputRequired">*</span>
                                    </div>}
                                    variant="outlined"
                                    value={formik.values.licensingInvestor}
                                    onChange={formik.handleChange}
                                    error={formik.touched.licensingInvestor && Boolean(formik.errors.licensingInvestor)}
                                    helperText={formik.touched.licensingInvestor && formik.errors.licensingInvestor}
                                    placeholder={language.Hotels.hotels_licensingInvestor} />

                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        inputVariant="outlined"
                                        name="facilityLicensingStartDate"
                                        className={`EditHotel-Input EditHotel-FacilityLicensingStartDateInput ${formik.touched.facilityLicensingStartDate && formik.errors.facilityLicensingStartDate ? 'EditHotel-FormInputError' : ''}`}
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline"
                                        label={<div>
                                            {language.Hotels.hotels_facilityLicensingStartDate}
                                            <span className="EditHotel-InputRequired">*</span>
                                        </div>}
                                        value={facilityLicensingStartDate}
                                        helperText={formik.errors.facilityLicensingStartDate}
                                        onChange={handleFacilityLicensingStartDateChange}
                                        error={formik.touched.facilityLicensingStartDate && Boolean(formik.errors.facilityLicensingStartDate)}
                                        KeyboardButtonProps={{
                                            'aria-label': language.Hotels.hotels_facilityLicensingStartDate,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography className={`EditHotel-AccordionHeadTitle`}>{language.Hotels.hotels_photos}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="EditHotel-FormDetailsContainer">
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps
                                }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button
                                            className="EditHotel-UploadPhoto"
                                            style={isDragging ? { color: "red" } : null}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            {language.Hotels.hotels_uploadPhotos}
                                        </button>
                                        &nbsp;
                                        <button className="EditHotel-RemoveAllPhoto" onClick={onImageRemoveAll}>{language.Hotels.hotels_photos_allRemove}</button>
                                        <div className="EditHotel-Images">
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                                <img src={image.data_url} alt="" width="100" />
                                                <div className="image-item__btn-wrapper">
                                                    <button className="EditHotel-UpdatePhoto" onClick={() => onImageUpdate(index)}>{language.Hotels.hotels_photos_update}</button>
                                                    <button className="EditHotel-RemovePhoto" onClick={() => onImageRemove(index)}>{language.Hotels.hotels_photos_remove}</button>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                )}
                            </ImageUploading>
                        </AccordionDetails>
                    </Accordion>
                    <div className="EditHotel-FormFooter">
                        <Button disabled={loading} className="EditHotel-NewButton" type="submit">
                            {loading ? <SpinnerButton /> : language.Hotels.hotels_edit}
                        </Button>
                        <NavLink to={'/admin/hotels'} className={"EditHotel-BackLink"} >
                            <Typography component={'span'} variant={'body2'} className={"EditHotel-BackLinkTitle"}>{language.Hotels.hotels_back}</Typography>
                        </NavLink>
                    </div>
                </form>
            </div>

            {statusAPI === 422
                ? <Snackbars openSnack={true} message={message} errors={error} statusCode={statusAPI} status={'error'} hideDuration={6000} />
                : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        FacilityType: state.facilityType.facilityType,
        Country: state.country.country,
        Cities: state.cities.cities,
        Areas: state.areas.areas,
        Stars: state.lookups.stars,
        Features: state.lookups.features,
        message: state.hotels.message,
        statusAPI: state.hotels.status,
        error: state.hotels.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchFacilityType: () => dispatch(fetchFacilityType()),
        onFetchCountry: () => dispatch(fetchCountry()),
        onFetchCitiesWithCountry: (countryID) => dispatch(fetchCitiesWithCountry(countryID)),
        onFetchAreasWithCity: (cityID) => dispatch(fetchAreasWithCity(cityID)),
        onFetchStars: () => dispatch(fetchStars()),
        onFetchFeatures: () => dispatch(fetchFeatures()),
        onAddHotel: (payload) => dispatch(addHotel(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHotel);