import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import SpinnerButton from "../../UI/SpinnerButton/SpinnerButton";

import { fetchPermissions, AddGroup } from "../../../store/actions/groups";

import './NewRole.css';

const NewRole = (props) => {
    const { language, Closed, status, loading, styleDir, lang, permissions = [], onFetchPermissions, onAddGroup } = props;

    const [permissionChecked, setPermissionChecked] = useState({});

    useEffect(() => {
        async function fetchData() {
            await onFetchPermissions();
            SetCheckedPermissions();
          }
          fetchData();
    }, []);

    useEffect(() => {
        if(status === 201) {
            Closed();
        }
    }, [status]);

    const SetCheckedPermissions = () => {
        let objectChecked = {};
        let Arr = permissions?.map((permission) => {
          return permission.map(item => {
            return { ...objectChecked, [item.id]: false };
          }
            );
        }
       );
       setPermissionChecked(objectChecked);
    }

    const validationSchema = yup.object({
        name: yup
            .string('Please enter a valid role name"')
            .required('Role name cannot be empty')
    });

    const formik = useFormik({
        initialValues: {
            name: '',
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            let permissionSubmit = [];
            for (let key in permissionChecked) {
              let objNew = permissionChecked[key];
              for(let aKey in objNew) {
                if(objNew[aKey] === true){
                  permissionSubmit.push(aKey);
                }
              }
            }

            let payload = {
                name: values.name,
                permissions: permissionSubmit
            };

            onAddGroup(payload);
        },
    });

    let count = 0;

    const handleChange = (event) => {
        let checkedNow = {
            [event.target.name]: event.target.checked
        };

        setPermissionChecked({ ...permissionChecked, [event.target.id] : checkedNow });
    };
    return (
        <React.Fragment>
            <div className="NewRoleContainer">
                <div className="NewRole-HeadContainer">
                    <Typography component={'span'} variant={'body2'} className="NewRole-HeadTitle">{language.UsersManagement.usersManagement_create_new_role}</Typography>
                    <CancelIcon onClick={() => Closed()} />
                </div>
                <div className="NewRoleLine"></div>
                <div className="NewRoleFormContainer">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="NewRoleFormContent">
                            <TextField
                                className={`NewRoleInput ${formik.touched.name && formik.errors.name ? 'NewRoleFormInputError' : ''}`}
                                id="name"
                                name="name"
                                label={<div>
                                    {language.UsersManagement.usersManagement_role_name}
                                    <span className="NewRoleInputRequired">*</span>
                                </div>}
                                variant="outlined"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                placeholder={language.UsersManagement.usersManagement_role_name} />

                            {Object.values(permissions).map((permission, index) => {
                                return (
                                    <div key={permission[0].group_name} className="boxs">
                                        <Typography component={'span'} variant={'body2'} className="NewRole-HeadPermissionTitle">{permission[0].group_name}</Typography>

                                        <div className="box-body">
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    {permission.map(item => {

                                                        let CheckboxRender = (<FormControlLabel
                                                            key={item.id}
                                                            control={
                                                                <Checkbox
                                                                    checked={Object.values({...permissionChecked[count]}).toString() === 'true' ? true : false}
                                                                    onChange={handleChange}
                                                                    id={count}
                                                                    name={item.id}
                                                                />
                                                            }
                                                            label={item.display_name.en}
                                                        />);
                                                        count++;
                                                        return CheckboxRender;
                                                    })}
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>

                        <div className="NewRoleFormFooter">
                            <Button disabled={loading} className="NewRoleNewRoleButton" type="submit">
                                {loading ? <SpinnerButton /> : language.UsersManagement.usersManagement_save}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.groups.loading,
        permissions: state.groups.permissions,
        status: state.groups.status,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchPermissions: () => dispatch(fetchPermissions()),
        onAddGroup: (payload) => dispatch(AddGroup(payload))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewRole));