import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    areas: [],
    message: null, 
    error: [],
    loading: false
};

const fetchAreasStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchAreasSuccess = (state, action) => {
    return updateObject(state, {
        areas: action.areas,
        error: [],
        loading: false
    });
};

const fetchAreasFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_AREAS_START: return fetchAreasStart(state, action);
        case actionTypes.FETCH_AREAS_SUCCESS: return fetchAreasSuccess(state, action);
        case actionTypes.FETCH_AREAS_FAIL: return fetchAreasFail(state, action);
        default: return state;
    }
};

export default reducer;