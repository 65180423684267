import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Popover from '@material-ui/core/Popover';

import { fetchGroups, resetGroups } from "../../store/actions/groups";

import NewRole from './NewRole/NewRole';
import EditRole from './EditRole/EditRole';

import Spinner from '../../components/UI/Spinner/Spinner';

import './Groups.css';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e7f3fd',
        color: '#393737',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
    body: {
        color: '#393737',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Cairo'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 700,
},
}));

const Groups = (props) => {
    const { loading, language, lang, styleDir, last_page, groups, onFetchGroups, onResetGroups } = props;

    const [filter, setFilter] = useState({});

    const [searchState, setSearchState] = useState();
    const [page, setPage] = useState(1);

    const [anchorElNewRole, setAnchorElNewRole] = useState(null);
    const [anchorElEditRole, setAnchorElEditRole] = useState(null);

    const [groupSelected, setGroupSelected] = useState();

    const searchHandler = (prop) => (event) => {
        setFilter({ ...filter, search: event.target.value });
        setSearchState(event.target.value)
    };

    useEffect(() => {
        onFetchGroups(page, filter);
    }, [page, filter, lang]);

    const resetHandleChange = (event) => {
        setSearchState('');
        onFetchGroups(page, {});
    }

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const newRoleHandleClick = (event) => {
        setAnchorElNewRole(event.currentTarget);
    };

    const newRolehandleClose = () => {
        setAnchorElNewRole(null);
        onResetGroups();
    };

    const editRoleHandleClick = (event, Group) => {
        setAnchorElEditRole(event.currentTarget);
        setGroupSelected(Group);
    };

    const editRolehandleClose = () => {
        setAnchorElEditRole(null);
        onResetGroups();
    };

    const openNewRole = Boolean(anchorElNewRole);
    const idNewRole = openNewRole ? 'mouse-over-popover-NewRole' : undefined;

    const openEditRole = Boolean(anchorElEditRole);
    const idEditRole = openEditRole ? 'mouse-over-popover-EditRole' : undefined;
  
    return (
        <div className="GroupsList-Container">
            <div className="GroupsList-HeadContainer">
                <Typography component={'span'} variant={'body2'} className="GroupsList-HeadTitle">{language.UsersManagement.usersManagement_groups}</Typography>
            </div>

            <div className="GroupsList-HeadNavContainer">
                <div className="GroupsList-UserNav">
                    <NavLink to='/admin/users' className="GroupsList-UsersNavLink" >
                        <Typography component={'span'} variant={'body2'} className="GroupsList-UserNavTitle">
                            <GroupIcon />
                            {language.UsersManagement.usersManagement_usersTitle}
                        </Typography>
                    </NavLink>
                </div>
                <div className="GroupsList-RolesNav">
                    <NavLink to='/admin/roles' className="GroupsList-RolesNavLink" >
                        <Typography component={'span'} variant={'body2'} className="GroupsList-RolesNavTitle">
                            <GroupWorkIcon />
                            {language.UsersManagement.usersManagement_roles}
                        </Typography>
                    </NavLink>
                </div>
            </div>
            <div className={`GroupsList-ReorderContent`}>
                <div className={`GroupsList-HeadContent`}>
                    <div className={`GroupsList-SearchContainer`}>
                        <TextField
                            className={`GroupsList-Input GroupsList-SearchInput`}
                            id="search"
                            name="search"
                            label={<div>
                                {language.UsersManagement.usersManagement_search}
                            </div>}
                            variant="outlined"
                            value={searchState}
                            onChange={searchHandler()}
                            placeholder={language.UsersManagement.usersManagement_search} />


                        <div onClick={resetHandleChange} className={`GroupsList-ResetContainer`}>
                            <RefreshIcon />
                            <Typography component={'span'} variant={'body2'} className="GroupsList-CountHeadTitle">{language.UsersManagement.usersManagement_reset}</Typography>
                        </div>

                    </div>
                    <div  aria-describedby={idNewRole} onClick={newRoleHandleClick} className="GroupsList-NewRoleButton">
                        {language.UsersManagement.usersManagement_new_role}
                    </div>
                </div>
                {loading
                    ? <Spinner />
                : <div className={classes.root}>
                {groups.map((group) => {
                    return (<Accordion key={group.id} className="GroupsList-Accordion" expanded={expanded === 'panel'+group.id} onChange={handleChange('panel'+group.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={'panel'+ group.id +'bh-content'}
                            id={'panel' + group.id +'bh-header'}
                        >
                            <Typography className={classes.heading}>{group.name}</Typography>
                            <div className="GroupsList-Action">
                                            <div aria-describedby={idEditRole} onClick={(event, Group) => editRoleHandleClick(event, group)}  className="GroupsList-EditButton" >
                                                <EditIcon /> {language.UsersManagement.usersManagement_edit}
                                            </div>
                                        </div>
                        </AccordionSummary>
                        <AccordionDetails>
                        <TableContainer className="UsersList-TableContainer" component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{language.UsersManagement.usersManagement_name}</StyledTableCell>
                                <StyledTableCell>{language.UsersManagement.usersManagement_emailAddress}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {group.users?.map((user) => (
                                <StyledTableRow key={user.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {user.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {user.email}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                        </AccordionDetails>
                    </Accordion>);
                })}

                        {groups.length === 0
                            ? <Typography component={'span'} variant={'body2'} className={"UsersList-NoData"}>{language.all.all_no_results}</Typography>
                            : null
                        }
                </div>
                }
            </div>

            <Popover
                className="UsersList-NewRoleContainer"
                id={idNewRole}
                open={openNewRole}
                anchorEl={anchorElNewRole}
                dir={language.dir}
                lang={language.language}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UseresList-PaperNewRole'}>
                    <NewRole Closed={newRolehandleClose} />
                </div>
            </Popover>

            <Popover
                className="UsersList-EditRoleContainer"
                id={idEditRole}
                open={openEditRole}
                anchorEl={anchorElEditRole}
                dir={language.dir}
                lang={language.language}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 50, left: 50 }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                <div className={'UseresList-PaperEditUser'}>
                    <EditRole Closed={editRolehandleClose} GroupSelected={groupSelected} />
                </div>
            </Popover>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        language: state.language.lang,
        lang: state.language.lang.language,
        styleDir: state.language.styleDir,
        loading: state.groups.loading,
        groups: state.groups.groups,
        last_page: state.groups.last_page
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchGroups: (payload, filter) => dispatch(fetchGroups(payload, filter)),
        onResetGroups: () => dispatch(resetGroups())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);