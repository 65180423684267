import React, { Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './hoc/Layout/Layout';

import Logout from './containers/Auth/Logout/Logout';
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword';
import Home from './containers/Home/Home';
import Admins from './containers/Admins/Admins';
import Groups from './containers/Admins/Groups';
import Cities from './containers/Cities/Cities';
import Areas from './containers/Cities/Areas';
import Hotels from './containers/Hotels/Hotels';
import NewHotel from './containers/Hotels/NewHotel';
import EditHotel from './containers/Hotels/EditHotel';
import ViewHotel from './containers/Hotels/ViewHotel';
import MapExplorer from './containers/MapExplorer/MapExplorer';
import HotelsByArea from './containers/Cities/Hotels';
import UnverifyHotelsByArea from './containers/Cities/UnverifyHotels';
import NextUnverifyHotels from './containers/Cities/NextUnverifyHotels';
import NextHotelByArea from './containers/Cities/NextHotel';

const Auth = React.lazy(() => {
  return import('./containers/Auth/Auth');
});

const App = props => {
  const { isAuthenticated } = props;

  let routes = (
    <Switch>
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/login" render={(props) => <Auth {...props} />} />
      <Redirect to="/login" />
    </Switch>
  );

  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/logout" component={Logout} />
        <Route path="/admin/hotels/view/:ID" exact component={ViewHotel} />
        <Route path="/admin/hotels/edit/:ID" exact component={EditHotel} />
        <Route path="/admin/hotels/create" exact component={NewHotel} />
        <Route path="/admin/map-explorer" exact component={MapExplorer} />
        <Route path="/admin/hotels" exact component={Hotels} />
        <Route path="/admin/area/:ID/hotel-unverify/:NextID" exact component={NextUnverifyHotels} />
        <Route path="/admin/area/:ID/hotel-unverify" exact component={UnverifyHotelsByArea} />
        <Route path="/admin/area/:ID/hotel" exact component={HotelsByArea} />
        <Route path="/admin/area/:ID/hotel/:NextID" exact component={NextHotelByArea} />
        <Route path="/admin/area_hotels/:ID" exact component={Areas} />
        <Route path="/admin/cities" exact component={Cities} />
        <Route path="/admin/roles" exact component={Groups} />
        <Route path="/admin/users" exact component={Admins} />
        <Route path="/home" exact component={Home} />
        <Redirect to="/home" />
      </Switch>
    );
  }

  return (
    <div>
      <Layout>
        <Suspense fallback={''}>{routes}</Suspense>
      </Layout>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

export default withRouter(connect(mapStateToProps)(App));
